import Vue from "vue";
import { debounce } from "lodash";
import elementKeys from "~/plugins/elementKeys";
import check from "~/store/elementChecker";
import { onClickOutside, useShare } from "@vueuse/core";
export default (
  { req, res, route, app, store, $axios, $clerk, $cookies, redirect, env },
  inject
) => {
  const mutateMe = (el, changes = {}) => {
    el = { ...el, ...changes };
  };

  const scheduled = (el) => {
    if (el.from || el.to) {
      let now = new Date().getTime();
      if (el.from) {
        let from = new Date(el.fromTimestamp).getTime();
        if (from > now) {
          el.fromHide = true;
        }
      }
      if (el.to) {
        let to = new Date(el.toTimestamp).getTime();
        if (to < now) {
          el.toHide = true;
        }
      }
      return !el.fromHide && !el.toHide;
    }
    return true;
  };

  const active = (el) => {
    try {
      el.isActive = eval(el.active);
    } catch (error) {}
  };

  const conditions = (el) => {
    // if(!process.browser){
    //   return;
    // }
    let valids = [];
    if (!(el.conditions || {}).length) {
      el.condition = true;
      return true;
    }
    valids = el.conditions.map((c) => {
      let keyValue = "";
      let item = store.getters.item;
      let $store = store.getters;
      let app = store.getters;
      if (c.key == "eval") {
        if ((c.variable + "").includes("dataLength.")) {
          let dataLength;
          let selector = c.variable.replace("dataLength.", "");
          let found;
          const pathFinder = (elements) => {
            for (let e of elements) {
              let data = e.data || e.repeatData || [];

              if (e.shopster_id == selector && data) {
                found = e;
                dataLength = (data || []).length;
                break;
              } else if (e.shopster_id == selector) {
                found = e;
                dataLength = (store.getters.elementData[e.uid] || []).length;
                break;
              }
              if (dataLength === undefined && e.elements) {
                pathFinder(e.elements);
              }
            }
          };
          pathFinder(store.getters.store.eshop.elements || []);
          c.variable = dataLength;
        }
        try {
          return eval(c.variable);
        } catch (error) {}
        return;
      } else if (c.key == "login") {
        keyValue = !!store.getters.token;
      } else if (c.key == "url") {
        keyValue = route.fullPath;
      } else if (c.key == "cartCost") {
        keyValue = store.getters.cart.totalC;
      } else if (c.key == "variable") {
        if (c.variable.includes("element.")) {
          // let element = el;
          // try {
          //   keyValue = eval(`${c.variable}`);
          // } catch (error) {
          //   console.error(error);
          // }
        } else {
          try {
            keyValue = eval(`store.getters.${c.variable}`);
          } catch (e) {
            try {
              keyValue = eval(
                `store.getters.${c.variable.replace("tags", "flat_tags")}`
              );
            } catch (error) {}
          }
        }
      }
      // add more keys
      if (c.comparison == "eq") {
        return keyValue == c.value;
      } else if (c.comparison == "neq") {
        return keyValue != c.value;
      } else if (c.comparison == "gt") {
        return keyValue > c.value;
      } else if (c.comparison == "gte") {
        return keyValue >= c.value;
      } else if (c.comparison == "lt") {
        return keyValue < c.value;
      } else if (c.comparison == "lte") {
        return keyValue <= c.value;
      } else if (c.comparison == "*") {
        return ((keyValue || "") + "").includes(c.value);
      } else if (c.comparison == "!*") {
        return !((keyValue || "") + "").includes(c.value);
      }
    });
    el.condition = valids.every((v) => v);
    return valids.every((v) => v);
  };

  const pseudo = (el, hover, key) => {
    if (hover && el[key + "Hover"]) {
      return el[key + "Hover"];
    } else {
      return el[key];
    }
  };
  const shadow = (el, hover) => {
    if (el.type == "coreImage") {
      return `filter:drop-shadow( ${pseudo(el, hover, "xShadow") || "0"}px ${
        pseudo(el, hover, "yShadow") || "0"
      }px ${pseudo(el, hover, "spreadShadow")}px ${pseudo(
        el,
        hover,
        "colorShadow"
      )})`;
    }
    return `box-shadow:${pseudo(el, hover, "xShadow") || "0"}px ${
      pseudo(el, hover, "yShadow") || "0"
    }px ${pseudo(el, hover, "spreadShadow")}px ${pseudo(
      el,
      hover,
      "blurShadow"
    )}px ${pseudo(el, hover, "colorShadow")}`;
  };

  const transform = (el) => {
    return `translate(${el.translateXHover || 0}px,${
      el.translateYHover || 0
    }px)scale(${parseFloat(el.scaleXHover) ? el.scaleXHover : 1},${
      parseFloat(el.scaleYHover) ? el.scaleYHover : 1
    })`;
  };

  const percentage = (v, skip) => {
    v = v + "";
    if ((v == "auto" || v == "") && !skip) {
      return "auto";
    }
    return isNaN(v) ? v : v + "px";
  };

  const shortHand = (el, p) => {
    if (!el[p]) {
      return "";
    }
    return `;${p}-top:${el[p].top}px;${p}-right:${el[p].right}px;${p}-bottom:${el[p].bottom}px;${p}-left:${el[p].left}px;`;
  };

  const setMaxWidth = () => {
    if (process.browser) {
      return window.innerWidth - 20 + "px";
    }
  };

  const repeat = (el, parentData, forced) => {
    if (el.scanned && !forced) {
      return el.elements;
    }
    let nested = (e, repeatIndex, delimiter) => {
      e.uid = `${e.uid}_${repeatIndex}`;
      if (repeatIndex > 0) {
        e.copy = true;
      }

      e.repeatIndex = repeatIndex;
      Object.keys(e).forEach((k) => {
        let strValue = e[k] + "";
        try {
          strValue = JSON.stringify(e[k]);
        } catch (error) {}
        if (
          !["elements", "uid", "cuid", "page", "type"].includes(k) &&
          strValue.includes(delimiter || "%%")
        ) {
          try {
            strValue
              .split(delimiter || "%%")
              .filter((x) => x && !x.match(/[<>="'\ ]/))
              .forEach((pp) => {
                let og = strValue.replaceAll(delimiter,'');
                let p = pp.replace(/{.*}/, "");
                try {
                  let scriptEval = `data[${e.repeatIndex}]${
                    p.includes("tag.") ? `['${p}']` : "." + p
                  }`;
                  if (p.includes("index")) {
                    scriptEval = p.replace("index","repeatIndex");
                  }
                  try {
                    let replacer;
                    if (
                      eval(scriptEval) !== undefined &&
                      strValue.includes(`${delimiter}`)
                    ) {
                      let value = eval(scriptEval);
                      if (!Array.isArray(value)) {
                        if (p.includes("tag.")) {
                          value = (value + "").split(" | ")[0];
                        }
                      } else if (e.type == "coreImage") {
                        value = value[0];
                      } else {
                        if (value.length) {
                          repeat(e, value);
                          return;
                        }
                      }
                      if (
                        value == "null" ||
                        value === "" ||
                        value === undefined
                      ) {
                        value = "";
                      }
                      replacer = value!==undefined?value:'';
                    } else if (
                      (strValue || "").includes(`${delimiter}${pp}${delimiter}`)
                    ) {
                      replacer = ""
                    }
                    if (replacer) {
                      try {
                        ((pp || "").match(/\{(.*?)\}/) || []).forEach((p) => {
                          if (!isNaN(p)) {
                            replacer = parseFloat(replacer).toFixed(p);
                          } else if (p == "round") {
                            replacer = Math.round(replacer + "");
                          } else if (p == "ceil") {
                            replacer = Math.ceil(replacer + "");
                          } else if (p == "floor") {
                            replacer = Math.floor(replacer + "");
                          } else if (p == "encode") {
                            replacer = encodeURIComponent(replacer);
                          }
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    }
                    strValue = strValue.replace(
                      `${delimiter}${pp}${delimiter}`,
                      replacer
                    );
                    try {
                      e[k] = JSON.parse(strValue.replace(/\r?\n|\r/g, "<br>"));
                    } catch (error) {
                      console.error(error);
                    }
                  } catch (error) {
                    console.warn(error);
                    e[k] = "";
                  }
                } catch (e) {
                  console.error(e);
                }
              });
          } catch (error) {
            console.error(error);
          }
        }
      });
      if (e.elements) {
        e.element = e.elements.map((ee) => {
          ee = nested(ee, repeatIndex, delimiter);
        });
      }
      return e;
    };
    let delimiter = "%%";
    if (el.repeatData?.length) {
    } else if (el.repeat == "itemImages") {
      el.repeatData = ((store.getters.item || []).images || []).map((i) => ({
        image: i,
      }));
    } else if ((el.repeat || []).includes("item.")) {
      let item = store.getters.item;
      try {
        el.repeatData = eval(el.repeat);
      } catch (error) {}
    } else if (parseInt(el.repeat)) {
      el.repeatData = [...Array(parseInt(el.repeat)).keys()];
    } else {
      try {
        if (parentData) {
          el.repeatData = eval(`parentData`);
        } else {
          el.repeatData = [];
          el.repeat = el.repeat.replace("app.", "");

          if (
            el.repeat.includes("%#") &&
            el.cuid &&
            el.repeatIndex !== undefined
          ) {
            let parentElData = store.getters.dataDump[el.cuid];
            el.repeatData = eval(
              `parentElData[${el.repeatIndex}].${el.repeat.replace(/%#/g, "")}`
            );
            delimiter = "%#";
            el.watchKey = `$store.getters.dataDump[${el.cuid}]`;
          } else {
            delimiter = "%%";
            let key = el.repeat;
            try {
              if (
                !el.repeat.includes("$nuxt.") &&
                !el.repeat.includes("$store.") &&
                !el.repeat.includes("window")
              ) {
                key = `store.getters.${el.repeat}`;
                el.watchKey = `$store.getters.${el.repeat}`;
              }
            } catch (e) {}

            el.repeatData = eval(key);
            // store.commit("dataDump", {
            //   key: el.uid,
            //   value: el.repeatData,
            // });
          }
        }
      } catch (e) {
        // console.warn(e);
        el.repeatData = [];
      }
    }
    if (!Array.isArray(el.repeatData)) {
      el.repeatData = [];
    }
    let data = el.repeatData || [];
    if ((data || []).length && (el.elements || []).length) {
      let els = [];
      for (let dindex = 0; dindex < data.length; dindex++) {
        els = [
          ...els,
          ...JSON.parse(JSON.stringify(el.elements)).map((oe) => {
            return nested(oe, dindex, delimiter);
          }),
        ];
      }
      el.elements = els;
    }
    el.scanned = true;
    return el.elements;
  };

  const actions = async (el, element, ctx) => {
    let actions = element.actions;
    if (!Array.isArray(actions) && !actions.length) {
      return;
    }
    let targetElement = element;
    if (!targetElement.styleImportant) {
      targetElement.styleImportant = {};
    }
    if (!targetElement.revert) {
      targetElement.revert = {};
    }
    const emmiter = (targets, changes) => {
      (targets || "").split(",").forEach((target) => {
        if (target == "self" || target == "window") {
          Vue.set(element, "revert", changes.revert);
          Vue.set(element, "styleImportant", changes.styleImportant);
        } else if (target) {
          $nuxt.$emit(`e${target}`, changes.styleImportant);
        }
      });
      ctx.$forceUpdate();
    };
    const dos = {
      function: (targetElement, a, t, d) => {
        if (d.code) {
          let definitions = `{let app = $nuxt;
          let item = $nuxt.$store.getters.item;
          let eshop = $nuxt.$store.getters.store.eshop;
          let user = $nuxt.$store.getters.user;
          let go = _=>app.$router.push(_);
          let element = ${JSON.stringify({
            ...targetElement,
            params: "",
            code: "",
          })
            .replace(/\\n/g, "\\\n")
            .replace(/\\r/g, "\\\n")};
          (async _=>{${d.code}})()
        }
          `;
          try {
            app.$hyperUtility.executioner(definitions);
          } catch (error) {
            console.error(error);
          }
        }
      },
      customEvent: (targetElement, a, t, d) => {
        if (d.event_custom) {
          $nuxt.$emit(d.event_custom, d.event_value);
        }
      },
      exec: (targetElement, a, t, d) => {
        if (d.func) {
          $nuxt[d.func]();
        }
      },
      share: (targetElement, a, t, d) => {
        const { share, isSupported } = useShare();
        share({
          title: "",
          text: "",
          url: location.href,
        });
      },

      notify: (targetElement, a, t, d) => {
        //
        let body = { open: true, title: d.title, to: d.to, copy: d.copy };
        if (d.type == "item") {
          body.item = $nuxt.$store.getters.item;
        }
        $nuxt.$store.commit("contact", body);
      },
      sendProduct: (targetElement, a, t, d) => {
        if (!t) {
          return;
        }
        if (d.email.includes("$$")) {
          try {
            d.email = document.querySelector(
              `[data-shopster_id="${d.email.replace("$$", "")}"] input`
            ).value;
          } catch (error) {
            console.error("element not found");
          }
        }
        if (d.barcode.includes("$$")) {
          try {
            d.barcode = document.querySelector(
              `[data-shopster_id="${d.barcode.replace("$$", "")}"] input`
            ).value;
          } catch (error) {
            console.error("element not found");
          }
        }
        app.$hyperItem.sendProduct(d.email, d.barcode);
      },
      gtm: (targetElement, a, t, d) => {
        if (d.gtm_event) {
          console.info(d.gtm_event);
          $nuxt.$emit(`informant:element`, {
            event: d.gtm_event,
            payload: d.gtm_payload,
          });
        }
      },
      scrollTo: (targetElement, a, t, d) => {
        if (d.handle) {
          app.$hyperUtility.scrollTo(d.handle);
        }
      },
      hide: (targetElement, a, t, d) => {
        // t.style.display = "none";
        // el.dataset["revert" + "display"] = "none";
        emmiter(a.target, {
          styleImportant: { display: "none" },
          revert: { display: "none" },
        });
        ctx.$forceUpdate();
      },
      show: (targetElement, a, t, d) => {
        // t.style.display = "inherit";
        // t.dataset["revert" + "display"] = "inherit";
        emmiter(a.target, {
          styleImportant: { display: "inherit" },
          revert: { display: "inherit" },
        });
      },
      toggle: (targetElement, a, t, d) => {
        emmiter(a.target, {
          styleImportant: {
            display: t.style.display == "none" ? "inherit" : "none",
          },
          revert: { display: t.style.display == "none" ? "inherit" : "none" },
        });
      },
      googleLogin: (targetElement, a, t, d) => {
        $nuxt.$emit("googleLogin");
      },
      googleOneTap: (targetElement, a, t, d) => {
        $nuxt.$emit("googleOneTap");
      },
      facebookLogin: (targetElement, a, t, d) => {
        $nuxt.$emit("facebookLogin");
      },
      emit_closeMenu: (targetElement, a, t, d) => {
        $nuxt.$emit("closeMenu");
      },
      emit_openMenu: (targetElement, a, t, d) => {
        $nuxt.$emit("openMenu");
      },
      quantityUp: (targetElement, a, t, d) => {
        $nuxt.$emit("quantityUp");
      },
      quantityDown: (targetElement, a, t, d) => {
        $nuxt.$emit("quantityDown");
      },
      addCart: (targetElement, a, t, d) => {
        //requires addToCart global function passed string of barcodes delimited with commas
        if (d.barcodes == "item" || !d.barcodes) {
          $nuxt.$emit("addToCart");
          return;
        }
        if (d.barcodes.includes("$$")) {
          try {
            d.barcodes = document.querySelector(
              `[data-shopster_id="${d.barcodes.replace("$$", "")}"] input`
            ).value;
          } catch (error) {
            console.error("element not found");
          }
        }
        app.$hyperCart.addFromBarcodes(d.barcodes);
      },
      toggleFavorite: (targetElement, a, t, d) => {
        app.$hyperItem.toggleFavorite();
        try {
          t.classList.toggle("active");
        } catch (error) {}
      },
      sliderGoTo: (targetElement, a, t, d) => {
        $nuxt.$emit(`sliderGoTo${d.shopster_id}`, d.slider);
      },
      propertyFree: (targetElement, a, t, d, el, ev, index) => {
        let targetDomEl = document.querySelector(
          `[data-shopster_id="${a.target}"]`
        );
        if (!targetDomEl) {
          targetDomEl = document.querySelector(
            `[data-uid="${targetElement.uid}"]`
          );
        }
        let targetScope = null;
        if (targetDomEl) {
          targetElement = targetDomEl.__vue__.elementLocal;
          targetScope = targetDomEl.__vue__;
          if (!targetElement) {
            targetElement = targetDomEl.__vue__.$parent.elementLocal;
            targetScope = targetDomEl.__vue__.$parent;
          }
        }
        if (!targetElement.toggles) {
          targetElement.toggles = {};
        }
        if (!targetElement.styleImportant) {
          $nuxt.$hyperUtility.vSet(targetElement, "styleImportant", {});
        }

        let k = elementKeys.find((e) => e.key == d.property) || {};
        if (k.type == "attr") {
          t.setAttribute(d.key, d.value);
        } else if (k.type == "class") {
          if (d.value == 0) {
            t.classList.remove(k.actual);
          } else {
            t.classList.add(k.actual);
          }
        } else if (k.type == "data") {
          t.dataset[k.actual] = d.value;
        } else if (k.type == "inner") {
          t.querySelector(k.selector).innerHTML = d.value;
        } else {
          if (
            targetElement.toggles.hasOwnProperty(index) &&
            a.event.includes("scroll") &&
            targetElement.scrolled
          ) {
            return;
          }
          if (targetElement.toggles.hasOwnProperty(index) && !d.once) {
            for (const key in targetElement.toggles[index]) {
              delete targetElement.styleImportant[key];
            }
            delete targetElement.toggles[index];
          } else {
            // targetElement.toggles[index] = targetElement[d.property];
            // targetElement[d.property] = d.value;
            if (!targetElement.toggles[index]) {
              targetElement.toggles[index] = {};
            }
            d.changes.forEach((c) => {
              targetElement.styleImportant[c.property] = c.value;
            });
            targetElement.toggles[index] = targetElement.styleImportant;
          }
          if (targetScope) {
            targetScope.$forceUpdate();
          }
        }
      },
      property: (targetElement, a, t, d, el, ev) => {
        //requires property && value
        if (ev.includes("scroll")) {
          if (el.style[d.property] === "") {
            el.dataset["revert" + d.property] = "";
          } else if (d.value != el.style[d.property]) {
            el.dataset["revert" + d.property] = el.style[d.property];
          }
          el.style[d.property] = d.value;
        } else {
          t.style[d.property] = d.value;
        }
      },
      propertyElement: (targetElement, a, t, d, el, ev, index) => {
        //TODO NESTED
        if (!targetElement.toggles) {
          targetElement.toggles = {};
        }
        if (targetElement.toggles.hasOwnProperty(index)) {
          targetElement[d.property] = targetElement.toggles[index];
          delete targetElement.toggles[index];
        } else {
          targetElement.toggles[index] = targetElement[d.property];
          targetElement[d.property] = d.value;
        }
        if (targetElement.type == "coreVideo") {
          document.querySelector(`.e${targetElement.uid} video`).load();
        }
      },
      propertyActive: (targetElement, a, t, d, el, ev) => {
        let k = elementKeys.find((e) => e.key == d.property) || {};
        if (k.type == "attr") {
          t.setAttribute(d.key, d.value);
        } else if (k.type == "class") {
          if (d.value == 0) {
            t.classList.remove(k.actual);
          } else {
            t.classList.add(k.actual);
          }
        } else if (k.type == "data") {
          t.dataset[k.actual] = d.value;
        } else if (k.type == "inner") {
          t.querySelector(k.selector).innerHTML = d.value;
        } else {
          if (ev.includes("scroll")) {
            if (el.style[k.actual] === "") {
              el.dataset["revert" + k.actual] = "";
            } else if (d.value != el.style[k.actual]) {
              el.dataset["revert" + k.actual] = el.style[k.actual];
            }
            el.style[k.actual] = d.value;
          } else {
            t.style[k.actual] = d.value;
          }
        }
      },

      newsLetter: (targetElement, a, t, d) => {
        try {
          if (!t) {
            return;
          }
          let e = t.querySelector(`input`).value;
          app.$hyperUser.newsLetterSign(e, false);
        } catch (error) {
          console.error(error);
        }
      },
      message: (targetElement, a, t, d, el, ev) => {
        //hyperAlert
        $clerk.hyperAlert({
          time: d.time,
          title: d.title,
          html: d.html,
          confirmButtonText: d.ok,
          cancelButtonText: d.cancel,
          icon: d.icon,
          iconHtml: d.iconHtml,
          cancelShow: d.cancel ? true : false,
          callback: (_) => {
            if (_) {
              if (d.callback) {
                d.callback.forEach(async (d) => {
                  if (d.do) {
                    await dos[d.do](targetElement, a, t, d, el, ev);
                  }
                });
              }
            }
          },
        });
      },
    };
    for (let a of actions) {
      let properties = { do: "propertyFree", changes: [] };
      a.dos = a.dos.reduce((acc, _) => {
        if (_.do == "propertyFree") {
          properties.once = _.once;
          properties.changes.push({ property: _.property, value: _.value });
        } else {
          acc.push(_);
        }
        return acc;
      }, []);
      if (properties.changes.length) {
        a.dos.push(properties);
      }
      let ev = a.event.includes("scroll") ? "scroll" : a.event;
      let targets = a.target.split(",");

      for (let tt of targets) {
        let target = tt;
        if (tt == "body") {
          target = document.body;
        } else if (tt == "window") {
          target = window;
        } else if (tt != "self" && tt != "window" && tt != "body" && tt) {
          target = await new Promise((res) => {
            let counter = 0;
            let timer = setInterval(() => {
              counter++;
              let targetEl = document.querySelector(
                `[data-shopster_id="${tt}"]`
              );
              if (targetEl || counter > 5) {
                clearInterval(timer);
                res(targetEl);
              }
            }, 50);
          });
        } else if (tt == "self") {
          target = el;
        }
        if (!target) {
          return;
        }
        if (ev == "clientLoaded") {
          setTimeout(() => {
            if (a.dos) {
              a.dos.forEach(async (d, index) => {
                if (d.do) {
                  await dos[d.do](targetElement, a, target, d, el, ev, index);
                }
              });
            }
          }, 10);
        } else if (ev == "clickOutside") {
          onClickOutside(target, (e) => {
            setTimeout(() => {
              if (a.dos) {
                a.dos.forEach(async (d, index) => {
                  if (d.do) {
                    await dos[d.do](targetElement, a, target, d, el, ev, index);
                  }
                });
              }
            }, 10);
          });
        } else if (ev == "observe") {
          //TODO classadd classremove
          setTimeout(() => {
            if (a.dos) {
              a.dos.forEach(async (d, index) => {
                if (d.do) {
                  await dos[d.do](targetElement, a, target, d, el, ev, index);
                }
              });
            }
          }, 10);
        } else if (ev.includes("nuxt_")) {
          let actualEvent =
            ev == "nuxt_custom" ? a.event_custom : ev.replace("nuxt_", "");
          $nuxt.$on(actualEvent, (_) => {
            if (a.dos) {
              a.dos.forEach(async (d, index) => {
                if (d.do) {
                  await dos[d.do](targetElement, a, target, d, el, ev, index);
                }
              });
            }
          });
        } else if (ev == "url") {
          let check = false;
          if (a.comparison == "eq") {
            check = location.pathname == a.event_value;
          } else if (a.comparison == "neq") {
            check = location.pathname != a.event_value;
          } else if (a.comparison == "*") {
            check = location.pathname.match(a.event_value + "");
          } else if (a.comparison == "!") {
            check = !location.pathname.match(a.event_value + "");
          }
          if (a.dos && check) {
            a.dos.forEach(async (d, index) => {
              if (d.do) {
                await dos[d.do](targetElement, a, target, d, el, ev, index);
              }
            });
          }
        } else if (ev == "variable") {
          if (!a.event_variable) {
            return;
          }
          $nuxt.$watch(`$nuxt.$store.state.${a.event_variable}`, (v) => {
            try {
              let check = false;
              if (a.comparison == "eq") {
                check = v == a.event_value;
              } else if (a.comparison == "neq") {
                check = v != a.event_value;
              } else if (a.comparison == "lt") {
                check = v < a.event_value;
              } else if (a.comparison == "lte") {
                check = v <= a.event_value;
              } else if (a.comparison == "gt") {
                check = v > a.event_value;
              } else if (a.comparison == "gte") {
                check = v >= a.event_value;
              } else if (a.comparison == "*") {
                check = (v + "").includes(a.event_value);
              } else if (a.comparison == "!*") {
                check = !(v + "").includes(a.event_value);
              }
              if (a.dos && check) {
                a.dos.forEach(async (d, index) => {
                  if (d.do) {
                    await dos[d.do](targetElement, a, target, d, el, ev, index);
                  }
                });
              }
            } catch (error) {}
          });
        } else {
          let enter = false;
          if (ev == "enter") {
            ev = "keyup";
            enter = true;
          }
          (tt == "body" || tt == "window" ? eval(tt) : el).addEventListener(
            ev,
            debounce(
              function (e) {
                if (enter && e.keyCode != 13) {
                  return;
                }
                e.preventDefault();
                if (ev == "scroll") {
                  let offset = a.offset;
                  if (store.getters.isMobile && a.offsetMobile) {
                    offset = a.offsetMobile;
                  }

                  if (
                    eval(
                      `${offset} ${a.scroll_comparison == "lt" ? "<" : ">"} ${
                        target[a.event]
                      }`
                    )
                  ) {
                    Vue.set(targetElement, "scrolled", false);
                  } else {
                    Vue.set(targetElement, "scrolled", true);
                    // return;
                  }
                  a.scrolled = targetElement.scrolled;
                }
                if (a.dos) {
                  a.dos.forEach(async (d, index) => {
                    if (d.do) {
                      await dos[d.do](
                        targetElement,
                        a,
                        target,
                        d,
                        el,
                        ev,
                        index
                      );
                    }
                  });
                }
              },
              ev == "scroll" ? 50 : 5,
              { trailing: true }
            )
          );
        }
      }
    }
  };
  const dropDownInit = (element) => {
    store.commit("elementEventPool", {
      key: `dd_${element.body}`,
      value: {
        type: "body",
        header: `dd_${element.header}`,
        parent: element.uid,
        maxHeight: element.maxHeight,
        transition: element.transition || `all .3s ease`,
        floating: element.floating,
        drawer: element.drawer,
        side: element.side,
        startOpenDekstop: element.startOpenDesktop,
        startOpenMobile: element.startOpenMobile,
        disabledDesktop: element.disabledDesktop,
        disabledMobile: element.disabledMobile,
      },
    });
    if (element.drawer) {
      element.style.zIndex = "unset";
      element.style.position = "static";
    }
    store.commit("elementEventPool", {
      key: `dd_${element.header}`,
      value: {
        type: "header",
        body: `dd_${element.body}`,
        parent: element.uid,
        onHover: element.onHover,
        disabledDesktop: element.disabledDesktop,
        disabledMobile: element.disabledMobile,
      },
    });
    store.commit("elementEventPool", {
      key: `dd_${element.closer}`,
      value: {
        type: "closer",
        body: `dd_${element.body}`,
        parent: element.uid,
      },
    });
  };
  const elementEventPool = (ctx, element) => {
    let eventPool = store.getters.elementEventPool;
    if (eventPool && eventPool[`dd_${element.shopster_id}`]) {
      if (eventPool[`dd_${element.shopster_id}`].type == "header") {
        let ddhead = eventPool[`dd_${element.shopster_id}`];
        if (
          store.getters.isMobile
            ? ddhead.disabledMobile
            : ddhead.disabledDesktop
        ) {
          return;
        }
        ctx.isDDHead = true;
        setTimeout(() => {
          let eventFunction = function (e, t) {
            let body = document.querySelector(
              `[data-shopster_id="${eventPool[
                `dd_${element.shopster_id}`
              ].body.replace("dd_", "")}"]`
            );
            if (body) {
              let proc;
              if (body.classList.contains("isOpen")) {
                body.classList.remove("isOpen");
              } else {
                body.classList.add("isOpen");
              }
              if (e.type == "mouseover") {
                proc = "open";
              } else {
                proc = "close";
              }
              $nuxt.$emit(`${eventPool[`dd_${element.shopster_id}`].body}`, {
                proc,
                hover: e.type == "click" ? false : true,
              });
            }
          };
          if (eventPool[`dd_${element.shopster_id}`].onHover) {
            document.querySelector(
              `[data-shopster_id="${element.shopster_id}"]`
            ).onmouseover = (_) => eventFunction(_);
            document.querySelector(
              `[data-uid="${eventPool[`dd_${element.shopster_id}`].parent}"]`
            ).onmouseleave = (_) => eventFunction(_, "parent");
          } else {
            document.querySelector(
              `[data-shopster_id="${element.shopster_id}"]`
            ).onclick = eventFunction;
          }
        }, 200);
      } else if (eventPool[`dd_${element.shopster_id}`].type == "body") {
        ctx.isDDBody = true;
        let ddbody = eventPool[`dd_${element.shopster_id}`];
        let style = { position: "relative", zIndex: 0, top: "0" };
        let togglerValue = "";
        if (ddbody.floating && !ddbody.drawer) {
          style.position = "absolute";
          style.zIndex = 10;
          style.top = "100%";
          style.left = 0;
        } else if (ddbody.drawer) {
          element.classes = (element.classes || "") + " builder-drawer";
          style.position = "fixed";
          style.zIndex = element.style.zIndex || 100;
          if (ddbody.side == "top") {
            togglerValue = "top";
            style.top = "0%";
            style.left = "50%";
            style.transform = "translateX(-50%)";
            style.right = "unset";
            style.bottom = "unset";
          } else if (ddbody.side == "right") {
            togglerValue = "right";
            style.right = "0%";
            style.top = "0%";
            style.left = "unset";
            style.bottom = "unset";
            style.transform = "";
          } else if (ddbody.side == "bottom") {
            togglerValue = "bottom";
            style.bottom = "0%";
            style.left = "50%";
            style.transform = "translateX(-50%)";
            style.top = "unset";
            style.right = "unset";
          } else if (ddbody.side == "left") {
            togglerValue = "left";
            style.left = "0%";
            style.top = "0%";
            style.bottom = "unset";
            style.right = "unset";
            style.transform = "";
          }

          setTimeout(() => {
            if (process.browser && document.querySelector(`.isBackdrop`)) {
              document.querySelector(`.isBackdrop`).onclick = (_) => {
                $nuxt.$emit(`dd_${element.shopster_id}`, {
                  proc: "close",
                });
              };
            }
          }, 200);
        }
        let maxHeight =
          !(store.getters.isMobile
            ? ddbody.disabledMobile
            : ddbody.disabledDesktop) || "100vh";
        if (
          !maxHeight &&
          (store.getters.isMobile
            ? ddbody.startOpenMobile
            : ddbody.startOpenDesktop)
        ) {
          maxHeight = "100vh";
        }
        element.styleImportant = {
          maxHeight: maxHeight || (!ddbody.drawer ? "0vh" : "100vh"),
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundColor: "#f0f0f0",
          ...style,
          [togglerValue]: ddbody.drawer ? "-100%" : "",
        };
        ctx.$nuxt.$on(`dd_${element.shopster_id}`, (_) => {
          if (
            typeof _ == "object" &&
            ((_.proc == "open" && ctx.isOpen) ||
              (_.proc == "close" && !ctx.isOpen && _.hover))
          ) {
            return;
          }
          ctx.isOpen = ctx.isOpen ? false : true;
          if (!ddbody.drawer) {
            if (ctx.isOpen) {
              element.styleImportant = {
                maxHeight: ddbody.maxHeight || "100vh",
                overflowX: "auto",
                overflowY: "auto",
              };
            } else {
              element.styleImportant = { maxHeight: "0vh" };
            }
          } else {
            if (ctx.isOpen) {
              document.querySelector(`.isBackdrop`).style.display = "block";
              element.styleImportant = {
                [togglerValue]: "0%",
                overflowX: "auto",
                overflowY: "auto",
              };
            } else {
              document.querySelector(`.isBackdrop`).style.display = "none";
              element.styleImportant = { [togglerValue]: "-100%" };
            }
            element.classes = !ctx.isOpen
              ? element.classes.replace("isOpen", "")
              : (element.classes += " isOpen");
          }
          ctx.$forceUpdate();
        });
      } else if (eventPool[`dd_${element.shopster_id}`].type == "closer") {
        if (process.browser && element.shopster_id) {
          setTimeout(() => {
            document.querySelector(
              `[data-shopster_id="${element.shopster_id}"]`
            ).onclick = (_) => {
              $nuxt.$emit(`${eventPool[`dd_${element.shopster_id}`].body}`, {
                proc: "close",
              });
            };
          }, 50);
        }
      }
    }
  };

  const getElements = async (page) => {
    let host = "";
    if (process.server) {
      host = req.headers.host + route.fullPath;
    } else {
      host = location.host + route.fullPath;
    }
    let preview = "";
    if (app.$cookies.get("preview")) {
      let pr = (app.$cookies.get("preview") || "").split(":::");
      if (pr[1] == store.getters.store.id) {
        preview = `&preview=${pr[0]}`;
      }
    }
    check(
      store.getters.store.id,
      route,
      app,
      preview,
      ((store.getters.store || {}).build_no || {}).timestamp,
      (store.getters.store.build || {}).build_no
    );
    try {
      let res = await $axios.get(
        `${process.env.axiosUrl}/stores/${
          store.getters.store.id
        }?elements=${encodeURIComponent(page)}&build=${
          (store.getters.store.build || {}).build_no
        }&styleless=true&elementsonly=true${preview}`,
        {
          headers: {
            shopster: host,
          },
        }
      );
      if ((res.data || {}).id) {
        let es = JSON.parse(JSON.stringify(store.getters.store));
        es.loadCss = [...(es.loadCss || []), page];
        res.data.eshop.elements = res.data.eshop.elements.map((e) => {
          delete e.data;
          return e;
        });

        es.eshop.elements =
          (await populateData({
            elements: res.data.eshop.elements,
            page,
            app,
          })) || [];
        es.eshop.elements = [
          ...store.getters.store.eshop.elements.filter((e) => e.static),
          ...es.eshop.elements,
        ];
        store.commit("store", es);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getRelatedFields = async (el) => {
    let fields = [];
    let nested = (e) => {
      Object.keys(e).forEach((k) => {
        let strValue = e[k] + "";
        try {
          strValue = JSON.stringify(e[k]);
        } catch (error) {}
        if (
          !["elements", "uid", "cuid", "page", "type"].includes(k) &&
          (strValue + "" || "").includes("%%")
        ) {
          strValue.match(/\%%(.*?)\%%/).forEach((p) => {
            if (!p.includes("%")) {
              fields.push(p.split("##")[0]);
            }
          });
        }
      });
      if (e.elements) {
        e.elements.forEach((ee) => {
          nested(ee);
        });
      }
      return e;
    };
    el = nested(el);
    el.related = true;
    el.fields = fields;
    return el;
  };

  const interceptor = ({ elements, ctx }) => {
    let namespace = (obj, path, og) => {
      var result = (path || "").split(".").reduce(function (value, index) {
        return value[index] || "";
      }, obj);
      try {
        if (result == "") {
          if (!path.includes("$nuxt") && !path.includes("$store")) {
            result = eval(`getters.${path}`);
          } else {
            result = eval(path);
          }
        }
        result = result.replace(/\"/g, '\\"');
      } catch (e) {
        try {
          result = eval(`${obj}.${path}`);
        } catch (error) {
          try {
            if (!path.includes("$nuxt") && !path.includes("$store")) {
              og = `store.getters.${og}`;
            }
            result = eval(`${og}.${path}`);
          } catch (e) {}
        }
      }
      return result;
    };
    let temp = JSON.stringify(elements) || "";
    temp = temp.split(/\@\@/g);
    temp = temp.map((t) => {
      if (t.includes("persons.") && !t.includes('"')) {
        let key = t.split("persons.")[1];
        return (
          ((ctx.item || {}).persons || []).filter((p) => p.key == key) || []
        )
          .map((p) => p.name || p.value)
          .join(" ή ");
      } else if (
        t.includes("tags.") &&
        !t.includes("_tags.") &&
        !t.includes('"')
      ) {
        let key = t.split("tags.")[1];
        return (((ctx.item || {}).tags || []).filter((p) => p.key == key) || [])
          .map((p) => p.name || p.value)
          .join(" ή ");
      } else if (t.includes("countdown:") && !t.includes('"')) {
        let tt = t.replace("countdown", "").split(":");
        if (tt[1]) {
          commit("timers", {
            input: tt[1],
            output: "",
            format: tt[2],
          });
        }
        return `{{timers[${tt[1]}].output}}`;
      } else if (
        !t.includes("@@") &&
        !t.includes('"') &&
        !t.includes("/") &&
        typeof t != "object" &&
        t
      ) {
        let tempContext = ctx;
        let obj = (t || "").split(".")[0];
        let tt = t.replace(/{.*}/, "");
        if (obj == "store") {
          tempContext = store.getters.store;
        } else if (obj == "eshop") {
          tempContext = store.getters.store.eshop;
        } else if (obj == "cart" || obj == "user" || obj == "app") {
          tempContext = store.getters;
          tt = tt.replace("app.", "");
        } else if (obj == "cms") {
          tempContext = store.getters.cms;
          tt = tt.replace("cms.", "");
        } else if (obj == "$nuxt") {
          tempContext = ctx.app.context;
          tt = tt.replace("$nuxt.", "");
        } else if (obj == "$route") {
          tempContext = route;
          tt = tt.replace("$route.", "");
        }
        let r = "";
        r = namespace(tempContext, tt, obj) || "";
        r += "";
        if (t.includes("history.items")) {
          return (
            (store.getters.history.items || []).map((i) => i.barcode) || []
          ).join(",");
        }
        if (r) {
          try {
            ((t || "").match(/\{(.*?)\}/) || []).forEach((p) => {
              if (!isNaN(p)) {
                r = parseFloat(r).toFixed(p);
              } else if (p == "round") {
                r = Math.round(r + "");
              } else if (p == "ceil") {
                r = Math.ceil(r + "");
              } else if (p == "floor") {
                r = Math.floor(r + "");
              }
            });
          } catch (error) {}
        }
        return (r + "").replace(/\t/g, " ").replace(/\r?\n|\r/g, "");
      }
      return `${t}`;
    });
    temp = (
      temp
        .filter((x) => x)
        .join("")
        .replace(/\@\@/g, "") || ""
    )
      .replace("/\r/g", "\\r")
      .replace("/\n/g", "\\n");
    try {
      return JSON.parse(temp);
    } catch (error) {
      console.warn(error);
      return elements;
    }
  };

  const singleInterceptor = ({ s, ctx }) => {
    let namespace = (obj, path, og) => {
      var result = (path || "").split(".").reduce(function (value, index) {
        return value[index] || "";
      }, obj);
      try {
        if (result == "") {
          if (!path.includes("$nuxt") && !path.includes("$store")) {
            result = eval(`ctx.store.getters.${path}`);
          } else {
            result = eval(path);
          }
        }
        result = result.replace(/\"/g, '\\"');
      } catch (e) {
        try {
          result = eval(`${obj}.${path}`);
        } catch (error) {
          try {
            if (!path.includes("$nuxt") && !path.includes("$store")) {
              og = `store.getters.${og}`;
            }
            result = eval(`${og}.${path}`);
          } catch (e) {}
        }
      }
      return result;
    };
    let temp = JSON.stringify(s) || "";
    temp = temp.split(/\@\@/g);
    temp = temp.map((t) => {
      if (t.includes("persons.") && !t.includes('"')) {
        let key = t.split("persons.")[1];
        return (
          ((ctx.item || {}).persons || []).filter((p) => p.key == key) || []
        )
          .map((p) => p.name || p.value)
          .join(" ή ");
      } else if (
        t.includes("tags.") &&
        !t.includes("_tags.") &&
        !t.includes('"')
      ) {
        let key = t.split("tags.")[1];
        return (((ctx.item || {}).tags || []).filter((p) => p.key == key) || [])
          .map((p) => p.name || p.value)
          .join(" ή ");
      } else if (t.includes("countdown:") && !t.includes('"')) {
        let tt = t.replace("countdown", "").split(":");
        if (tt[1]) {
          commit("timers", {
            input: tt[1],
            output: "",
            format: tt[2],
          });
        }
        return `{{timers[${tt[1]}].output}}`;
      } else if (
        !t.includes("@@") &&
        !t.includes('"') &&
        !t.includes("/") &&
        typeof t != "object" &&
        t
      ) {
        let tempContext = ctx;
        let obj = (t || "").split(".")[0];
        let tt = t.replace(/{.*}/, "");
        if (obj == "store") {
          tempContext = store.getters.store;
        } else if (obj == "eshop") {
          tempContext = store.getters.store.eshop;
        } else if (obj == "cart" || obj == "user" || obj == "app") {
          tempContext = store.getters;
          tt = tt.replace("app.", "");
        } else if (obj == "cms") {
          tempContext = store.getters.cms;
          tt = tt.replace("cms.", "");
        } else if (obj == "$nuxt") {
          tempContext = ctx.context;
          tt = tt.replace("$nuxt.", "");
        } else if (obj == "$route") {
          tempContext = ctx.context.route;
          tt = tt.replace("$route.", "");
        }
        let r = "";
        r = namespace(tempContext, tt, obj) || "";
        r += "";
        if (t.includes("history.items")) {
          return (
            (store.getters.history.items || []).map((i) => i.barcode) || []
          ).join(",");
        }
        if (r) {
          try {
            ((t || "").match(/\{(.*?)\}/) || []).forEach((p) => {
              if (!isNaN(p)) {
                r = parseFloat(r).toFixed(p);
              } else if (p == "round") {
                r = Math.round(r + "");
              } else if (p == "ceil") {
                r = Math.ceil(r + "");
              } else if (p == "floor") {
                r = Math.floor(r + "");
              } else if (p == "encode") {
                r = encodeURIComponent(r);
              }
            });
          } catch (error) {}
        }
        return (r + "").replace(/\t/g, " ").replace(/\r?\n|\r/g, "");
      }
      return `${t}`;
    });
    temp = (
      temp
        .filter((x) => x)
        .join("")
        .replace(/\@\@/g, "") || ""
    )
      .replace("/\r/g", "\\r")
      .replace("/\n/g", "\\n");
    try {
      return JSON.parse(temp);
    } catch (error) {
      return s;
    }
  };

  const populateData = async (ctx, external) => {
    let getters = store.getters;
    let checkType = (e) =>
      (e.type == "row" ||
        e.type == "list" ||
        e.type == "prefixRow" ||
        e.type == "coreRow" ||
        e.type == "coreRepeat") &&
      !e.skipData &&
      ctx.page.includes(e.page);
    let es = [];
    if (ctx.elements) {
      es = ctx.elements;
    } else {
      es = JSON.parse(JSON.stringify(store.getters.store.eshop.elements));
    }
    let cmsBulk = [];
    let cmsCheck = {};
    let cmsDepedencies = async (es) => {
      return await Promise.all(
        es.map(async (e) => {
          if (e.cms) {
            for (const c of (e.cms || "").split(",") || []) {
              let title = c.split(":::")[0];
              let q = c.split(":::")[1];
              q = singleInterceptor({ s: q, ctx: app });
              let property = c.split(":::")[2];
              let reversed = c.split(":::")[3];
              if ((!store.getters.cms[c] && !cmsCheck[c]) || q) {
                let tt = await app.$cms.get({
                  store_id: store.getters.store.id,
                  title,
                  property,
                  q,
                  limit: 100,
                  urlOnly: true,
                  post: true,
                  reversed,
                });
                if (tt.url) {
                  cmsBulk.push(tt);
                }
                cmsCheck[c] = true;
              }
            }
          }
          if ((e.elements || []).length) {
            e.elements = await cmsDepedencies(e.elements);
          }
          return e;
        })
      );
    };
    es = await cmsDepedencies(es);
    if (cmsBulk.length) {
      try {
        cmsBulk = await $axios.$post(
          `${process.env.axiosUrl}/utilities/bulk`,
          {
            requests: cmsBulk,
            cache_key:
              cmsBulk.map((b) => b.title).join("_") +
              ($cookies.get("token") || ""),
          },
          {
            headers: {
              Authorization: `Bearer ${$cookies.get("token")}`,
              store_id: store.getters.store.id,
            },
          }
        );
        cmsBulk.forEach((c) => {
          store.commit("cms", { key: c.title, data: c.result?.docs });
        });
      } catch (error) {
        // console.error(error);
      }
    }
    // es = await interceptor({
    //   elements: es,
    //   ctx,
    // });
    let elementParser = async (els, fn) => {
      return await Promise.all(
        els.map(async (el) => {
          if (el.elements) {
            await elementParser(el.elements, fn);
          }
          return fn(el);
        })
      );
    };
    elementParser(es, async (el) => {
      if (el.dirty?.length) {
        for (const k of el.dirty) {
          el[k] = await singleInterceptor({ s: el[k], ctx: app });
        }
      }
      return el;
    });
    let ssrData = async (e) => {
      if (!e.serverData) {
        e.serverData = {};
      }
      try {
        let fields = e.ssrData.split(",");
        for (const f of fields) {
          try {
            let key = f.split(":")[0];
            let url = f.split(":")[1];
            url = encodeURIComponent(
              url
                .replace(/\//g, "1__1")
                .replace(/\\/g, "2__2")
                .replace(/\&/g, "3__3")
                .replace(/\=/g, "4__4")
                .replace(/\?/g, "5__5")
            )
              .replace(/3__3/g, "&")
              .replace(/4__4/g, "=")
              .replace(/5__5/g, "?")
              .replace(/2__2/g, "/")
              .replace(/1__1/g, "/");
            let t = await $axios.get(`${process.env.axiosUrl}/${url}`);

            e.serverData[key] = t.data;
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    let bulk = [];
    let parseElementsTree = async (es, parentHref) => {
      try {
        es = await Promise.all(
          es.map(async (e) => {
            if (e.type == "coreRepeat" && !e.related) {
              if (e.repeat == "itemQuery") {
                e = await ctx.app.$hyperElement.getRelatedFields(e);
              }
            }
            return e;
          })
        );
      } catch (err) {
        console.warn(err);
      }
      for (let ei = 0; ei < es.length; ei++) {
        let e = es[ei];
        if (parentHref) {
          delete e.href;
        }

        let validParams = (e.params || []).reduce((acc, p) => {
          acc += p.value + "" + (p.tag_value + "");
          if (p.key == "favorites") {
            acc += "1";
          }
          return acc;
        }, "");
        if (
          e.type == "coreRepeat" &&
          e.repeat == "blog" &&
          !(e.repeatData || []).length
        ) {
          let tt = await ctx.app.$hyperRemote.getBlogData({
            e,
            urlOnly: true,
          });
          if (tt.url) {
            bulk.push(tt);
          }
        } else if (checkType(e) && ((e.params || [])[0] || {}).key == "local") {
          let app = getters;
          let store = getters.store;
          let eshop = getters.store.eshop;
          let cart = getters.cart;
          let item = getters.item;
          let user = getters.user;
          let history = getters.history;
          try {
            if ((e.params[0].value + "").includes("{{")) {
              let tt = (e.params[0].value.match(/{{(.*?)}}/g) || []).map(
                (match) => match.replace(/{{|}}/g, "")
              )[0];
              // e.data = eval(tt);
              es[ei].localBind = tt;
            } else {
              e.data = eval(e.params[0].value);
            }
          } catch (err) {
            console.warn(err);
          }
        } else if (
          checkType(e) &&
          !JSON.stringify(e.params + "").includes("@@") &&
          validParams
        ) {
          let tt = await ctx.app.$hyperRemote.getData({
            e,
            cookies: $cookies,
            app: ctx.app,
            urlOnly: true,
          });
          bulk.push(tt);
          if (ctx.item && tt.data) {
            try {
              tt.data = ((tt || {}).data || []).filter(
                (i) => i.barcode != ctx.item.barcode
              );
            } catch (error) {
              console.warn(error);
            }
          }
          e = tt;
        }
        if (e.ssrData) {
          await ssrData(e);
        }
        if (store.getters.elementData[e.uid]) {
          store.commit("elementData", { uid: e.uid, data: e.data });
        }
        if ((e.elements || []).length) {
          e.elements = await parseElementsTree(
            e.elements,
            e.href || parentHref
          );
        }
      }
      return es;
    };
    let ees = await parseElementsTree(es);
    if (bulk.length) {
      try {
        bulk = await $axios.post(
          `${process.env.axiosUrl}/utilities/bulk`,
          {
            requests: bulk,
            cache_key:
              bulk.map((b) => b.uid).join("_") + ($cookies.get("token") || ""),
          },
          {
            headers: {
              Authorization: `Bearer ${$cookies.get("token")}`,
              store_id: store.getters.store.id,
            },
          }
        );
        bulk = bulk.data;
      } catch (error) {
        // console.error(error);
      }
      let bulkParse = async (es) => {
        for (let i = 0; i < es.length; i++) {
          let e = es[i];
          try {
            let r = bulk.find((b) => b.uid == e.uid);
            if (r && r.result) {
              if (r.proc == "getBlogData") {
                e.repeatData = r.result.docs;
              } else if (r.proc == "getData") {
                let row = r.result;
                if (e.fields && row.items.docs && row.items.docs[0]) {
                  e.repeatData = row.items.docs;
                }
                if (
                  row.items &&
                  row.items.docs &&
                  row.items.docs[0] &&
                  !e.fields
                ) {
                  if (
                    (e.params.filter((f) => f.key == "barcodes") || []).length
                  ) {
                    let barcodes = (
                      (e.params.filter((f) => f.key == "barcodes") || [])[0] ||
                      {}
                    ).value;
                    row.items.docs = row.items.docs.sort((a, b) => {
                      return (
                        barcodes.indexOf(a.barcode) -
                        barcodes.indexOf(b.barcode)
                      );
                    });
                  }
                  let d = await app.$hyperItem.prepareItems(row.items.docs);
                  if (external) {
                    store.commit("elementData", { key: e.uid, value: d });
                  }
                  e.data = d;
                } else {
                  e.data = [];
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
          if ((e.elements || []).length) {
            e.elements = await bulkParse(e.elements);
          }
        }
        return es;
      };
      ees = await bulkParse(ees);
    }
    let parseElementRepeatTree = (e, megaSlider) => {
      if (e.type == "coreRepeat") {
        e.elements = repeat(e);
      }
      e.inMegaSlider = megaSlider;
      if (e.elements) {
        e.elements = e.elements.map((el, eindex) =>
          parseElementRepeatTree(el, e.type == "coreMegaSlider")
        );
      }

      return e;
    };
    let checkElement = (e) => {
      if (!scheduled(e)) {
        return false;
      }
      if (
        ["row", "coreRow", "list", "prefixRow"].includes(e.type) &&
        !(store.getters.elementData[e.uid] || e.data || []).length &&
        !e.localBind
      ) {
        return false;
      }
      if (e.type == "orders" && !store.getters.token) {
        return false;
      }

      return true;
    };

    const conditionalElement = (es, filterFn) => {
      return es.reduce((acc, e) => {
        const ee = { ...e };
        if (ee.elements) {
          ee.elements = conditionalElement(ee.elements, filterFn);
        }
        if (filterFn(ee)) {
          acc.push(ee);
        }
        return acc;
      }, []);
    };
    ees = conditionalElement(ees, (e) => checkElement(e));
    ees = ees.filter((e) => parseElementRepeatTree(e));
    return ees;
  };
  const ref = (e, key, ref) => {
    let ctx = { route, store, app, $cookies, redirect };
    if (store) {
      let compounder = (path, ctx) => {
        try {
          let p = path.split(".");
          let t = ctx || store.getters;
          p.forEach((pp) => {
            if (pp.match(/(\[.*?\])/)) {
              let tt = pp.split("[");
              t = t[tt[0]];
              t = t[tt[1].replace("]", "")];
              return t;
            }
            t = t[pp];
          });
          return t;
        } catch (error) {
          return "";
        }
      };
      //NOTE - ## toFixed, ##2 toFixed(2), ^^ toUpperCase, >> toLowerCase
      let tt = (ref.match(/{{(.*?)}}/g) || []).map((match) => {
        let m = match.replace(/{{|}}/g, "");
        let operation = {};
        if (m.includes("##")) {
          operation.toFixed = m.split("##")[1];
          m = m.split("##")[0];
        } else if (m.includes("^^")) {
          operation.toUpperCase = true;
          m = m.split("^^")[0];
        } else if (m.includes(">>")) {
          operation.toLowerCase = true;
          m = m.split(">>")[0];
        }
        let v = ref.replace(match, compounder(m));
        if (v == "undefined") {
          v = ref.replace(match, compounder(m, ctx));
        }
        if (operation.toFixed !== undefined) {
          try {
            v = parseFloat(v).toFixed(parseInt(operation.toFixed));
          } catch (error) {}
        } else if (operation.toUpperCase) {
          v = v.toUpperCase();
        } else if (operation.toLowerCase) {
          v = v.toLowerCase();
        }
        return v;
      });
      return tt + "";
    }
    return "";
  };

  const importantReactiveStyle = (e) => {
    let pseudo = "";
    if (store.getters.isMobile) {
      pseudo = "Mobile";
    }
    if (e.hover) {
      pseudo += "Hover";
    }

    if (!e.rendered) {
      e.rendered = {};
    }
    let rendered = {};
    Object.keys(e.styleImportant || {}).forEach((k) => {
      rendered[k] = e.styleImportant[k];
    });
    e.rendered[pseudo] = rendered;
    return rendered;
  };
  const isFunctional = (e) => {
    if (
      false &&
      (e.type == "coreContainer" ||
        e.type == "coreText" ||
        e.type == "coreImage" ||
        e.type == "coreButton") &&
      !(e.text + "").includes("{{") &&
      !(e.text + "").includes("%%") &&
      !(e.actions || []).length &&
      !e.slider &&
      !e.forceSlider
    ) {
      return "element-builder-functional";
    }
    return "element-builder";
  };

  inject("hyperElement", {
    mutateMe,
    scheduled,
    conditions,
    active,
    shadow,
    pseudo,
    transform,
    percentage,
    shortHand,
    setMaxWidth,
    repeat,
    actions,
    dropDownInit,
    elementEventPool,
    getElements,
    getRelatedFields,
    populateData,
    ref,
    interceptor,
    singleInterceptor,
    importantReactiveStyle,
    isFunctional,
  });
};
